import React from 'react';
import { Helmet } from 'react-helmet';

import SignUpThankyou from 'components/pages/signup/signup-thankyou';

export default function SignUpThankyouPage() {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Sign Up - Blue Flag</title>
                <link rel="canonical" href="https://blueflag.com.au/signup-thankyou/" />

                <meta name="description" content="Sign up to the Blue Flag Vehicle Data Platform" />
                <meta name="keywords" content="Blueflag sign up, Sign Up Blue Flag" />
            </Helmet>
            <SignUpThankyou />
            {/* <Footer /> */}
        </>
    );
}
