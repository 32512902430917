import './signup.scss';
import 'components/view/Hubspot.scss';
import React from 'react';
import { Link } from 'gatsby';

import Container from 'components/layout/Container';
import Section from 'components/layout/Section';

// import { trackEvent } from 'utils/googleAnalytics'

const SignUpThankYou = () => {
    return (
        <Container theme="light-blue" className="container-signUp signUp paddingY5">
            <Section className="sectionLeft"></Section>

            <Section className="sectionRight">
                <div className="innerRight">
                    <Link className="signUpLogo" to="/">
                        <img src="/assets/logos/blueflag-logo.svg" alt="Blue Flag logo" width="156px" height="31px" />
                    </Link>

                    <h2 className="signUpTitle">Thank you for signing up!</h2>
                    <p className="signUpSubTitle">We appreciate your interest in our services. One of our team members will be in touch with you shortly.</p>
                </div>
            </Section>
        </Container>
    );
};

export default SignUpThankYou;
